exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-blood-plasma-index-jsx": () => import("./../../../src/pages/blood-plasma/index.jsx" /* webpackChunkName: "component---src-pages-blood-plasma-index-jsx" */),
  "component---src-pages-capabilities-statement-copy-index-jsx": () => import("./../../../src/pages/capabilities-statement copy/index.jsx" /* webpackChunkName: "component---src-pages-capabilities-statement-copy-index-jsx" */),
  "component---src-pages-capabilities-statement-index-jsx": () => import("./../../../src/pages/capabilities-statement/index.jsx" /* webpackChunkName: "component---src-pages-capabilities-statement-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-freight-damage-index-jsx": () => import("./../../../src/pages/freight-damage/index.jsx" /* webpackChunkName: "component---src-pages-freight-damage-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-404-index-jsx": () => import("./../../../src/pages/page-404/index.jsx" /* webpackChunkName: "component---src-pages-page-404-index-jsx" */),
  "component---src-pages-page-about-2-index-jsx": () => import("./../../../src/pages/page-about-2/index.jsx" /* webpackChunkName: "component---src-pages-page-about-2-index-jsx" */),
  "component---src-pages-page-about-5-index-jsx": () => import("./../../../src/pages/page-about-5/index.jsx" /* webpackChunkName: "component---src-pages-page-about-5-index-jsx" */),
  "component---src-pages-page-about-app-index-jsx": () => import("./../../../src/pages/page-about-app/index.jsx" /* webpackChunkName: "component---src-pages-page-about-app-index-jsx" */),
  "component---src-pages-page-blog-5-index-jsx": () => import("./../../../src/pages/page-blog-5/index.jsx" /* webpackChunkName: "component---src-pages-page-blog-5-index-jsx" */),
  "component---src-pages-page-contact-5-index-jsx": () => import("./../../../src/pages/page-contact-5/index.jsx" /* webpackChunkName: "component---src-pages-page-contact-5-index-jsx" */),
  "component---src-pages-page-services-5-index-jsx": () => import("./../../../src/pages/page-services-5/index.jsx" /* webpackChunkName: "component---src-pages-page-services-5-index-jsx" */),
  "component---src-pages-page-services-details-5-index-jsx": () => import("./../../../src/pages/page-services-details-5/index.jsx" /* webpackChunkName: "component---src-pages-page-services-details-5-index-jsx" */),
  "component---src-pages-page-shop-5-index-jsx": () => import("./../../../src/pages/page-shop-5/index.jsx" /* webpackChunkName: "component---src-pages-page-shop-5-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-receipt-of-goods-index-jsx": () => import("./../../../src/pages/receipt-of-goods/index.jsx" /* webpackChunkName: "component---src-pages-receipt-of-goods-index-jsx" */),
  "component---src-pages-refund-policy-index-jsx": () => import("./../../../src/pages/refund-policy/index.jsx" /* webpackChunkName: "component---src-pages-refund-policy-index-jsx" */),
  "component---src-pages-request-a-quote-index-jsx": () => import("./../../../src/pages/request-a-quote/index.jsx" /* webpackChunkName: "component---src-pages-request-a-quote-index-jsx" */),
  "component---src-pages-return-policy-index-jsx": () => import("./../../../src/pages/return-policy/index.jsx" /* webpackChunkName: "component---src-pages-return-policy-index-jsx" */),
  "component---src-pages-shipping-policy-index-jsx": () => import("./../../../src/pages/shipping-policy/index.jsx" /* webpackChunkName: "component---src-pages-shipping-policy-index-jsx" */),
  "component---src-pages-terms-of-service-index-jsx": () => import("./../../../src/pages/terms-of-service/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-jsx" */),
  "component---src-pages-warranty-index-jsx": () => import("./../../../src/pages/warranty/index.jsx" /* webpackChunkName: "component---src-pages-warranty-index-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/CategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

